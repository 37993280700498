<template>
  <div class="reject-modal">
    <h1 class="camembert">
      Комментарий для автора
    </h1>
    <r-select
      class="mt-8"
      :items="reasonList"
      v-model="reason"
    />
    <r-textarea
      class="mt-6"
      label="Комментарий"
      :rows="9"
      v-model="message"
    />
    <r-button
      class="mt-10"
      title="Отправить"
    />
    <p class="mt-2 mozzarella text-center anie">
      Комментарий будет отправлен автору на его личный E-mail
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reasonList: [
        { id: 1, value: 'Нарушение правил портала' },
        { id: 2, value: 'Описание не соответствует' },
        { id: 3, value: 'Изображения плохого качества' },
        { id: 4, value: 'Объект уже добавлен' },
        { id: 5, value: 'Другое' }
      ],
      reason: 1,
      message: null
    };
  }
};
</script>

<style lang="scss" scoped>
.reject-modal {
  width: 618px;
  margin: 0 auto;
}
</style>
