<template>
  <div class="flex">
    <div class="upload">
      <div>
        <div class="parent align-center">
          <div class="container-upload">
            <h1 class="ricotta mb-4">
              Сохранить изменения?
            </h1>
            <p class="mb-8 font">
              Вы внесли изменения в объект культуры «{{ title }}»
            </p>
            <div class="mt-2 buttons">
              <r-button
                class="flex-1"
                type="secondary"
                width="wide"
                title="Не сохранять"
                @click.native="close()"
              />
              <r-button
                class="flex-1"
                @click.native="save"
                width="wide"
                title="Да, сохранить"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeleteModal',
  props: {
    saveFunc: Function,
    title: String,
    items: Object
  },
  data() {
    return {
      upload: false
    };
  },
  methods: {
    close() {
      document.querySelectorAll('.RModal__close')[0].click();
    },
    async save() {
      this.saveFunc();
    }
  }
};
</script>

<style lang="scss" scoped>
.buttons {
  display: grid;
  grid-template-columns: 380px 380px;
  grid-gap: 32px;
  @media(max-width: 792px) {
    grid-template-columns: 1fr;
  }
}

.container-upload {
  width: 87%;
  height: 300px;
}

.upload {
  background-color: white;
  z-index: 999;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
}

.parent {
  display: block;
  position: absolute;
  width: 40%;
  height: 400px;
  top: 33%;
  margin-left: 31%;
}
</style>
<style>
.RModal__close {
  top: 0px !important;
  z-index: 1000;
}
</style>
