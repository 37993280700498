import ApiService from './ApiService';

export default class PlaceApi extends ApiService {
  async getTypes(payload) {
    const { data } = await this._axios.post('ajax.php?action=getPlacesTypes', {
      params: payload
    });
    return data;
  }

  async getAddress(payload) {
    const { data } = await this._axios.post('mob_ajax.php?action=geosearch', payload);
    return data;
  }

  async getUserInfo() {
    const { data } = await this._axios.get('nginxApi.php?get=user');
    // const {data} = await this._axios.get(`/auth/rest/user`);
    return data;
  }

  async getList(payload) {
    const noCache = Math.floor(Math.random(1) * 1000);
    const { data } = await this._axios.post('ajax.php?action=getPlaces&wHidden=1&v=2', payload);
    return data;
  }

  async getTransport(payload) {
    const { lat, lng } = payload;
    const { data } = await this._axios.get(
      `transportProxy.php?url=stations&lat=${lat}&lng=${lng}&radius=500`
    );
    return data;
  }

  async setItem(payload) {
    const { data } = await this._axios.post('/ajax.php?action=addNewPlace', payload);
    return data;
  }

  async setRating(payload) {
    const { data } = await this._axios.post('/ajax.php?action=operatePublicRating', payload);
    return data;
  }

  async setOperate(payload) {
    const { data } = await this._axios.post('/ajax.php?action=operatePlace', payload);
    return data;
  }
}
