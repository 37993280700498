<template>
  <div>
    <h1 class="mb-3">
      Ошибка 404
    </h1>
    <h2>Страница не найдена</h2>
    <router-link
      :to="{ name: 'admin'}"
      class="text-rocky mt-4"
    >
      Вернуться на главную
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
};
</script>

<style scoped>
div {
  text-align: center;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column
}
</style>
