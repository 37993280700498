<template>
  <div class="objects">
    <h1 class="ricotta mb-7">
      Объекты культуры
    </h1>
    <r-tabs
      class="mt-7"
      :items="tabItems"
      v-model="activeTabId"
    />
    <div class="mt-6 flex">
      <router-link
        class="flex align-center sulguni text-rocky"
        :to="{ name: 'new' }"
      >
        <r-icon
          class="mr-2"
          icon="add"
        />Добавить вручную
      </router-link>
    <!--  <span class="flex align-center sulguni ml-6 upload-color text-rocky pointer" @click="clickUploadContainer">
      <rir-icon class="mr-2" icon="add"/>
      Загрузить данные
    </span> -->
    </div>
    <div class="objects__filter">
      <r-select
        v-show="activeTabId.id !== 'moderation'"
        class="flex-1 mr-6"
        select-all
        select-first-item
        :items="objectTypes"
        label="Тип объекта"
        v-model="selectedCategory"
      />
      <r-select
        class="flex-1"
        :items="sortTypes"
        label="Сортировать"
        v-model="selectedSortType"
      />
      <r-input
        before-icon="search"
        class="flex-1 ml-6"
        label="Поиск"
        v-model="search"
      />
      <div
        v-show="activeTabId.id !== 'moderation'"
        class="flex ml-6"
      >
        <r-button-simple
          size="larishae"
          icon="icon-view"
          icon-size="20"
          @click="selectedView = 'table'"
          :type="selectedView === 'map' ? 'secondary' : 'primary'"
        />
        <r-button-simple
          v-show="activeTabId.id !== 'moderation'"
          size="larishae"
          class="ml-2"
          icon="geopoint"
          icon-size="20"
          :type="selectedView === 'map' ? 'primary' : 'secondary'"
          @click="selectedView = 'map'"
        />
      </div>
    </div>

    <loader v-if="isLoading" />
    <template v-else>
      <div class="opacity-48 text-c16 color-titanic wrap-word mt-24px text-right">
        {{ !!searchText ? 'Найдено' : 'Всего' }} {{ filteredObjects.length }}
      </div>
      <div
        v-show="activeTabId.id === 'moderation' || selectedView === 'table'"
        class="objects__list mt-6"
      >
        <card
          v-for="item in filteredObjects"
          :key="item.uid"
          :active-tab-id="activeTabId.id"
          :uid="item.uid"
          :img="item.img"
          :title="item.title"
          :address="item.address"
          :count="item.count"
          :purge-deadline="item.purgeDeadline"
        >
          <div
            class="flex align-center"
            v-if="item.purgeDeadline"
          >
            <r-icon
              class="mr-2"
              icon="block"
              fill="fargo"
            />
            <span
              class="briscola text-fargo"
              :style="{ color: 'var(--rir-fargo)' }"
            >
              Будет удалено
              {{ formatDate(item.purgeDeadline) }}</span>
          </div>
          <div
            v-else
            class="flex align-cenetr"
          >
            <div class="flex mr-5">
              <r-icon
                class="mr-2"
                icon="image"
                :fill="item.counts.img ? 'rocky' : 'fargo'"
              />
              <span
                class="briscola"
                :style="!item.counts.img ? { color: 'var(--rir-fargo)' } : null"
              >{{ item.counts.img }}</span>
            </div>
            <div class="flex mr-5">
              <r-icon
                class="mr-2"
                icon="music"
                :fill="item.counts.audio ? 'rocky' : 'fargo'"
              />
              <span
                class="briscola"
                :style="!item.counts.audio ? { color: 'var(--rir-fargo)' } : null"
              >{{ item.counts.audio }}</span>
            </div>
            <div class="flex">
              <r-icon
                class="mr-2"
                icon="star"
                :fill="item.counts.rating ? 'rocky' : 'fargo'"
              />
              <span
                class="briscola"
                :style="!item.counts.rating ? { color: 'var(--rir-fargo)' } : null"
              >{{ item.counts.rating || '-,-' }}</span>
            </div>
          </div>
        </card>
      </div>
      <div
        v-show="selectedView === 'map' && activeTabId.id !== 'moderation'"
        class="objects__map mt-6"
        ref="map"
      >
        <rir-map v-if="isShowMap && filteredObjects.length">
          <ymap-marker
            v-for="marker in filteredObjects"
            :key="marker.uid"
            :coords="[`${marker.lat}`, `${marker.lng}`]"
            :marker-id="marker.uid"
            :options="{ hideIconOnBalloonOpen: false }"
            :icon="$markerIcon()"
            @click="onMarkerClick(marker.uid)"
            @balloonopen="bindListener"
            @balloonclose="unbindListener"
            cluster-name="main"
          >
            <balloon-card
              slot="balloon"
              :title="marker.title"
              :address="marker.address"
              :img="marker.img"
              :data-id="marker.uid"
            >
              <div class="flex mt-3 justify-space-between">
                <button
                  class="opacity-72"
                  data-action="copy"
                  transparent
                  contentWidth
                >
                  <r-icon
                    class="mr-2"
                    icon="add"
                    size="16"
                    fill="rocky"
                  />
                  <div class="briscola rocky--text inline-flex absolute">
                    Дублировать
                  </div>
                </button>
                <button
                  transparent
                  contentWidth
                  data-action="delete"
                >
                  <r-icon
                    class="opacity-72"
                    icon="delete"
                    fill="fargo"
                    size="16"
                  />
                </button>
              </div>
            </balloon-card>
          </ymap-marker>
        </rir-map>
      </div>
      <upload ref="upload" />
      <not-found v-show="filteredObjects.length < 1" />
    </template>
  </div>
</template>

<script>
import Card from '@/components/ObjectCard.vue';
import RirMap from '@/components/RirMap.vue';
import BalloonCard from '@/components/BalloonCard.vue';
import notFound from '../components/notFound';
import Upload from '@/components/Upload';
import Loader from '@/components/Loader.vue';

export default {
  name: 'ObjectList',
  components: {
    Loader,
    Card,
    RirMap,
    BalloonCard,
    notFound,
    Upload
  },
  data() {
    return {
      sortTypes: [
        {
          id: 1,
          title: 'По названию'
        },
        {
          id: 2,
          title: 'По количеству'
        }
      ],
      selectedView: 'table',
      selectedCategory: null,
      selectedSortType: 1,
      activeTabId: {
        id: 'published',
        title: 'Опубликовано',
        count: this.countOnPublic
      },
      countOnPublic: null,
      countOnModerate: null,
      searchText: null,
      timeout: null,
      selectedMarkerId: null,
      isShowMap: false
    };
  },
  computed: {
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchText = val;
        }, 420);
      }
    },

    allObjects() {
      // console.log('allObjects', this.$store.state.objects);
      this.countOnModerate = this.countOnPublic = null;
      if (!this.$store.state?.objects?.length) return [];

      const objects = this.$store.state.objects.filter(el => el.id && el.lat);
      return objects
        .map(el => {
          if (el?.id) {
            el.approved === '1' ? this.countOnPublic++ : this.countOnModerate++;
            return {
              uid: el.id,
              img: (el.images?.length && el.images[0]?.url) || el?.photo1 || (el.images[0] ? el.images[0] : null),
              category: el.typeId,
              purgeDeadline: el.purgeDeadline,
              hidden: el.hidden,
              approved: el.approved,
              title: el.name || '-',
              address: el.address || '-',
              lat: el.lat,
              lng: el.lng,
              counts: {
                img: el.images?.length || 0,
                audio: el.audioUrls?.length || 0,
                rating: el.rating
              }
            };
          }
        })
        .sort((a, b) => a.title.localeCompare(b.title));
    },

    filteredObjects() {
      const objects = [];
      if (this.selectedCategory !== null && this.activeTabId.id !== 'moderation') {
        objects.push(
          ...this.allObjects.filter(el => this.selectedCategory === String(el.category))
        );
      } else if (this.activeTabId.id === 'moderation') {
        objects.push(...this.allObjects.filter(el => el.approved === '0'));
      } else {
        objects.push(...this.allObjects.filter(el => el.approved === '1'));
      }

      // console.log('filtered', objects);

      if (this.searchText) {
        return objects.filter(
          el => el.title.toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) >= 0
        );
      }

      return objects;
    },

    objectTypes() {
      if (!this.$store.state.types?.map) return [];
      return this.$store.state.types.map(el => ({
        id: el.id,
        title: el.type
      }));
    },
    isLoading() {
      return this.$store.state.isObjectLoading;
    },

    tabItems() {
      const tabs = [
        {
          id: 'published',
          title: 'Опубликовано',
          optionsBulb: {
            color: 'rocky',
            isShape: false,
            title: this.countOnPublic,
            position: 'eluno'
          }
        },
        {
          id: 'moderation',
          title: 'На модерации',
          optionsBulb: {
            color: 'fargo',
            isShape: false,
            title: this.countOnModerate,
            position: 'eluno'
          }
        }
      ];
      return tabs;
    }
  },

  activated() {
    this.isShowMap = true;
  },
  deactivated() {
    this.isShowMap = false;
  },

  async mounted() {
    !this.objectTypes?.length && this.$store.dispatch('loadTypes');
    this.$store.dispatch('loadObjects');
  },
  methods: {
    clickUploadContainer() {
      this.$refs.upload.uploadContainer();
    },
    onMarkerClick(id) {
      this.selectedMarkerId = id;
    },

    // Обработчики для Balloon'a карты
    onBalloonClick(e) {
      // console.log('onBalloonClick', e);
      const closest = e.target.closest('button');
      const id = this.selectedMarkerId;

      if (!closest) {
        this.$router.push({
          name: 'edit',
          params: { id }
        });
        return;
      }

      if (closest.getAttribute('data-action') === 'copy') {
        this.$router.push({ name: 'copy', params: { id } });
        // this.$router.push({
        //   name: 'edit',
        //   params: { id },
        //   query: { copy: true }
        // });
      } else if (closest.getAttribute('data-action') === 'delete') {
        this.onDelete();
      }
    },

    // Обрабочики для Balloon'a карты
    bindListener() {
      if (!this.selectedMarkerId) return;

      const balloon = this.$refs.map.querySelector(
        `.r-map-balloon[data-id="${this.selectedMarkerId}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    },
    unbindListener() {
      if (!this.selectedMarkerId) return;
      const balloon = this.$refs.map.querySelector(
        `.r-map-balloon[data-id="${this.selectedMarkerId}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    },

    async onDelete() {
      if (!this.selectedMarkerId) return;
      const res = await this.$store.dispatch('deleteObject', this.selectedMarkerId);
      if (!res.error) {
        await this.$store.dispatch('loadObjects');
        this.$router.push({ name: 'admin' });
      } else {
        // console.log('delete error');
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleString('Ru-ru', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      });
    },
    loadObjects(typeId) {
      this.$store.dispatch('loadObjects', typeId);
    }
  }
};
</script>

<style lang="scss" scoped>
.objects {
  &__filter {
    margin-top: 24px;
    display: flex;
  }
  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;
    > * {
      min-width: 0;
    }
  }
  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}
</style>
<style>
.RBulb__badge {
  background-color: unset !important;
}
</style>
