<template>
  <div class="flex">
    <div
      v-show="upload"
      class="upload"
    >
      <div>
        <div @click="upload = !upload">
          <r-icon
            class="mr-2 margin-right"
            icon="close"
            size="32"
          />
        </div>
        <div class="parent align-center">
          <div
            class="container-upload"
            v-if="!load"
          >
            <h1 class="ricotta mb-6">
              Загрузка данных
            </h1>
            <upload-file
              ref="childComponent"
              @uploadEvent="uploadFile"
              @uploadError="uploadError"
              :accept-file="['.csv']"
              :count-file="2"
              upload-url="/cityProperty/rest/service/upload"
              :value="file"
              :count-line="1"
              description="или перетяните сюда файл csv, созданый на основе шаблона"
            />
            <div class="flex mt-8">
              <r-button
                class="flex-1"
                @click="click"
                title="Загрузить"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadFile from '@/components/dragUploadFile.vue';

export default {
  name: 'Upload',
  components: {
    UploadFile
  },
  props: ['item'],
  data() {
    return {
      filterStartDate: null,
      filterEndDate: null,
      file: [],
      load: false,
      upload: false,
      modelEnd: [],
      server: process.env.VUE_APP_API_SERVER,
      fileUpload: false,
      showUpload: false,
      error: false
    };
  },
  methods: {
    save() {
      if ((this.filterStartDate != null) && (this.filterEndDate != null)) {
        window.location.href = `/cityProperty/rest/service/change/list?from=${this.filterStartDate}&to=${this.filterEndDate}`;
      }
    },
    click() {
      this.$refs.childComponent.handleFiles();
    },
    uploadFile() {
      this.upload = false;
      this.showUpload = true;
      setTimeout(this.showUp, 4000);
    },
    uploadError() {
      this.error = true;
      this.upload = false;
      this.showUpload = true;
      setTimeout(this.showUp, 10000);
    },
    uploadContainer() {
      this.showUpload = false;
      this.load = false;
      this.upload = true;
    },
    showUp() {
      this.showUpload = false;
    },
    loadContainer() {
      this.showUpload = false;
      this.load = true;
      this.upload = true;
    }
  }
};
</script>

<style scoped>
.align-center {
  align-items: center;
  float: left;
  margin-top: 5px;
}

.upload-color {
  color: #3d75e4;
  cursor: pointer;
}

.upload {
  background-color: white;
  z-index: 999;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.parent {
  display: block;
  position: absolute;
  width: 40%;
  height: 400px;
  top: 50%;
  margin-left: 33%;
  margin-top: -150px;
}

.container-upload {
  width: 87%;
  height: 300px;
}

.margin-right {
  float: right;
  margin-top: 17px;
  margin-right: 17px;
  z-index: 1000;
  cursor: pointer;
}

.float-left {
  float: left;
}

.font {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  opacity: 0.72;
}

.width-container {
  max-width: 100%;
}
.load-change{
  z-index: 1000;
  position: fixed;
  height: 56px;
  width: 70%;
  left: 30%;
  right: 0%;
  top: calc(100% - 56px);
  background: #FFFFFF;
  box-shadow: 0px 8px 32px rgba(39, 20, 2, 0.12);
  border-radius: 28px;
}
.image-load {
  margin: 12px;

  float: left;
}
.load-text {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
}
.load-text-end {
  margin-top: 18px;
}
.load-text-error {
  margin-top: 14px;
}
.load-text-right {
  cursor: pointer;
  float: right;
  margin-top: -18px;
  margin-right: 24px;
}
.text {
  float: left;
  margin-left: 7px;
  color: #E14761;
}
.float-left {
  float: left;
}
.error-load{
  position: absolute;
  height: 16px;
  left: 58px;
  top: calc(50% - 16px/2 + 14px);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #34040F;
  opacity: 0.48;
}
</style>
