import Vue from 'vue';
import Vuex from 'vuex';
import PlaceApi from '@/api/PlaceApi';

const api = new PlaceApi();

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    types: null,
    objects: null,
    selectedObject: null,
    isObjectLoading: null,
    userInfo: null
  },
  mutations: {
    setTypes(state, types) {
      state.types = types;
    },
    setObjects(state, objects) {
      state.objects = objects;
    },
    setSelectedObject(state, id) {
      state.selectedObject = { ...state.objects.filter(el => +el.id === +id)[0] } || null;
    },
    setObjectLoading(state, status) {
      state.isObjectLoading = status;
    },
    setUserInfo(state, res) {
      state.userInfo = res;
    }

  },
  getters: {
    getTypes: () => state.objects.types,
    getObjectById: state => id => state.objects.find(el => el.id === id)
  },
  actions: {
    async selectObject(ctx, id) {
      if (!ctx.state.objects?.length) {
        const res = await api.getList({ readOnly: 1, type: 0, currentMainMenuNumber: 5 });
        ctx.commit('setObjects', res.all);
      }
      const selected = ctx.state.objects.find(el => el.id === id);
      ctx.commit('setSelectedObject', selected);
    },

    async loadObjects(ctx, typeId = 0) {
      ctx.commit('setObjectLoading', true);
      const res = await api.getList({ readOnly: 1, type: typeId, currentMainMenuNumber: 5 });
      ctx.commit('setObjects', res.all);
      ctx.commit('setObjectLoading', false);
    },

    async loadTypes(ctx) {
      const res = await api.getTypes();
      console.log('load types', res);
      ctx.commit('setTypes', res.types);
    },

    async restoreObject(ctx, id) {
      // SON: {id:Int, action: update|delete|save, item:{},coordinates:[Int,Int]}
      ctx.commit('setObjectLoading', true);
      const res = await api.setOperate({
        id,
        action: 'update',
        item: {
          purgeDeadline: null,
          hidden: '0'
        }
      });
      return res;
      console.log('delete res', res);
    },
    async deleteObject(ctx, id) {
      // SON: {id:Int, action: update|delete|save, item:{},coordinates:[Int,Int]}
      ctx.commit('setObjectLoading', true);
      const res = await api.setOperate({ id, action: 'delete' });
      console.log('delete res', res);
      return res;
    },
    async addObject(ctx, { item, coordinates }) {
      // SON: {id:Int, action: update|delete|save, item:{},coordinates:[Int,Int]}
      const payLoad = JSON.stringify({ action: 'update', coordinates, item });
      console.log('payLoad', payLoad);
      const res = await api.setOperate(payLoad);
      console.log('add object res', res);
      return res;
    },
    async getUserInfo(ctx) {
      const res = await api.getUserInfo();
      ctx.commit('setUserInfo', res);
    }
  },
  modules: {}
});
