<template>
  <div class="r-map-balloon">
    <img
      class="r-map-balloon__img"
      :src="img"
      alt=""
    >
    <div class="r-map-balloon__info">
      <p class="roquefort">
        {{ title }}
      </p>
      <p class="mt-1 сaprino">
        {{ address }}
      </p>
      <div class="mt-3 mb-3">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BalloonCard',
  props: {
    title: {
      type: String
    },
    address: {
      type: String
    },
    img: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map-balloon {
  width: 200px;
  &__img {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
  }
  &__info {
    padding: 8px 16px 1px 16px;
    word-wrap: break-word;
  }
}
</style>
