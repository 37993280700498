<template>
  <section class="rir-drag-upload-file__body">
    <div
      class="rir-drag-upload-file__uploading"
      v-show="isUpload"
    >
      <span>Загрузка...</span>
      <div
        :style="{
          width: `${progress}%`
        }"
        class="rir-drag-upload-file__uploading--progress"
      />
    </div>
    <label
      v-show="!isUpload"
      ref="upload"
      class="rir-drag-upload-file"
      :class="{
        'rir-drag-upload-file--max-files': disabledUpload
      }"
      :for="getUuId"
    >
      <div class="rir-drag-upload-file__content">
        <div>
          <r-icon :icon="icon" />
          <span v-if="title">{{ title }}</span>
        </div>
        <span
          v-if="disabledUpload"
        >Вы уже загрузили максимум файлов</span>
        <span v-else>{{ getTextCountFiles }}</span>
      </div>
      <input
        :disabled="disabledUpload"
        ref="input"
        :accept="acceptingFiles"
        :id="getUuId"
        type="file"
        multiple
        @change="load($event.target.files)"
      >
    </label>
    <div
      v-if="disabledUpload"
      class="files"
    >
      <div class="files_icon">
        <r-icon
          icon="file"
          size="20"
          class="files_icon-margin"
        />
      </div>
      <div class="files-body">
        <div style="max-width: calc(100% - 30px)">
          <div class="files-name flex text-c16 text-titanic">
            {{ filesLoad[0].name }}
          </div>
          <div class="files-size text-rocky mozzarella">
            {{ fileSize }}
          </div>
        </div>
        <template>
          <r-icon
            icon="delete"
            class="files-delete"
            fill="fargo"
            @click.native="removeFile()"
          />
        </template>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'DragUploadFile',
  props: {
    title: {
      type: String,
      default: 'Выбрать файлы'
    },
    icon: {
      type: String,
      default: 'attach'
    },
    iconFill: {
      type: String,
      default: 'rocky'
    },
    description: {
      type: String,
      default: ''
    },
    value: {
      type: Array
    },
    countFile: {
      type: Number,
      default: 2
    },
    countLine: {
      type: Number,
      default: 2
    },
    listView: {
      type: String,
      default: 'DEFAULT'
    },
    showSize: {
      type: Boolean,
      default: true
    },
    acceptFile: {
      type: Array,
      default: () => ['.jpg', '.jpeg', '.png', '.gif']
    },
    uploadUrl: {
      type: String,
      require: true
    },
    paramsBody: {
      type: Object,
      default: () => ({})
    }
  },
  data: vm => ({
    filesLength: 0,
    filesCountUpload: vm.value.length,
    isUpload: false,
    filesLoad: '',
    disabledUpload: false,
    dataLoad: null,
    fileSize: ''
  }),

  computed: {
    acceptingFiles() {
      return this.acceptFile.join(',');
    },
    getUuId() {
      return this.uuIdv4();
    },
    getTextCountFiles() {
      if (this.description) return this.description;
      const text = `или перетяните сюда${
        this.countFile ? ` до ${this.countFile} файлов` : ''
      }`;
      return `${text}, объёмом не более 50 МБайт`;
    },
    progress() {
      return ((this.filesCountUpload / this.filesLength) * 100).toFixed(3);
    }
  },
  mounted() {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.$refs.upload.addEventListener(
        eventName,
        this.preventDefaults,
        false
      );
    });
    ['dragenter', 'dragover'].forEach(eventName => {
      this.$refs.upload.addEventListener(eventName, this.highlight, false);
    });
    ['dragleave', 'drop', 'dragend'].forEach(eventName => {
      this.$refs.upload.addEventListener(eventName, this.unHighlight, false);
    });
    ['drop'].forEach(eventName => {
      this.$refs.upload.addEventListener(eventName, this.handleDrop, false);
    });
  },
  beforeDestroy() {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.$refs.upload.removeEventListener(
        eventName,
        this.preventDefaults,
        false
      );
    });
    ['dragenter', 'dragover'].forEach(eventName => {
      this.$refs.upload.removeEventListener(eventName, this.highlight, false);
    });
    ['dragleave', 'drop', 'dragend'].forEach(eventName => {
      this.$refs.upload.removeEventListener(eventName, this.unHighlight, false);
    });
    ['drop'].forEach(eventName => {
      this.$refs.upload.removeEventListener(eventName, this.handleDrop, false);
    });
  },
  methods: {
    preventDefaults(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    highlight() {
      this.$refs.upload.classList.add('highlight');
    },
    unHighlight() {
      this.$refs.upload.classList.remove('highlight');
    },
    load(files) {
      this.filesLoad = files;
      if (files[0].size > 1024 * 1024) {
        const size = files[0].size / 1024 / 1024;
        this.fileSize = `${size.toFixed(1)} МБайт`;
      } else {
        const size = files[0].size / 1024;
        this.fileSize = `${size.toFixed(0)} КБайт`;
      }
      this.disabledUpload = true;
    },
    handleDrop(e) {
      const dt = e.dataTransfer;
      const { files } = dt;
      this.load(files);
    },
    async handleFiles(files) {
      if (this.filesLoad != '') {
        const filesList = this.filesLoad;

        if (filesList.length) this.isUpload = true;
        this.filesLength = filesList.length;
        await this.uploadFile();
      }
    },
    async uploadFile() {
      const re = /(?:\.([^.]+))?$/;
      const fileExt = this.filesLoad;
      this.filesCountUpload += 1;

      return new Promise(resolve => {
        const url = this.uploadUrl;
        const formData = new FormData();
        formData.append('file', fileExt[0]);
        formData.append('filename', fileExt[0].name);

        formData.append('userId', undefined);
        const params = Object.keys(this.paramsBody);
        if (params.length !== 0) {
          params.forEach(key => {
            formData.append(key, this.paramsBody[key]);
          });
        }
        this.disabledUpload = false;
        fetch(url, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            ...this.paramsHeaders
          },
          body: formData
        })
          .then(function (response) {
            const _this = this;
            if (response.status !== 200) {
              throw Error(`error load image - ${response.status}`);
            } else {
              return response.json();
            }
          })
          .then(data => {
            if (String(data.status) == String('ok')) {
              this.$emit('uploadEvent');
            } else {
              this.$emit('uploadError');
            }
            this.filesLoad = '';
          })
          .finally(() => {
            if (this.progress >= 100) {
              this.isUpload = false;
              this.$refs.input.value = '';
            }
            resolve();
          });
      });
    },
    removeFile() {
      this.disabledUpload = false;
      this.filesLoad = '';
    }
  }
};
</script>
<style lang="scss" scoped>
.files-delete {
  cursor: pointer;
  margin-left: 13px;
}

.files {
  margin-top: 24px;
  color: #04153E;
  margin-bottom: 10px;
  height: 48px;
  border-radius: 8px;
  background: rgba(#3D75E4, 0.08);
  display: flex;
  align-items: center;
}

.files_icon {
  height: 100%;
  width: 76px;
  border-radius: 8px;
  background: #E4EDFB;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.files-name {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.files-body {
  height: 100%;
  width: calc(100% - 76px);
  padding-left: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
