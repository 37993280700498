<template>
  <div>
    <router-link
      :to="{
        name: 'edit',
        params: { id: uid },
        query: activeTabId === 'moderation' ? { moderate: true } : null
      }"
    >
      <div class="obj-card">
        <div :class="['obj-card__img', { 'opacity-32': purgeDeadline }]">
          <img
            v-if="img"
            :src="img"
            alt=""
            loading="lazy"
          >
          <r-icon
            v-else
            icon="mayor"
            size="32"
            fill="rocky"
          />
        </div>
        <div class="obj-card__info flex-1">
          <div :class="['obj-card__title sulguni', { 'opacity-32': purgeDeadline }]">
            {{ title }}
          </div>
          <div :class="['obj-card__address mozzarella', { 'opacity-32': purgeDeadline }]">
            {{ address }}
          </div>

          <div class="obj-card__counters flex align-center">
            <slot />
          </div>
        </div>

        <div class="flex align-center">
          <additional-menu v-if="!purgeDeadline">
            <ul class="obj-card__menu">
              <li
                class="sulguni flex pointer"
                @click.stop.prevent="copyItem"
              >
                <r-icon
                  class="mr-3"
                  icon="copy"
                  fill="rocky"
                />Дублировать
              </li>
              <li
                class="sulguni flex mt-6 pointer"
                @click.stop.prevent="deleteItem"
              >
                <r-icon
                  class="mr-3"
                  icon="delete"
                  fill="fargo"
                />Удалить
              </li>
            </ul>
          </additional-menu>
          <button
            class="obj-card__restore"
            v-else
            @click.stop.prevent="onRestore"
          >
            <r-icon
              icon="restore"
              fill="rocky"
            />
          </button>
        </div>
      </div>
    </router-link>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
  </div>
</template>

<script>
import AdditionalMenu from './AdditionalMenu.vue';
import ModalReject from './ModalReject';
import DeleteModal from './DeleteModal';

export default {
  components: { AdditionalMenu },
  props: {
    activeTabId: {
      type: String
    },
    uid: {
      type: String,
      required: true
    },
    img: {
      type: [String, null],
      default: null
    },
    title: {
      type: String,
      required: true
    },
    address: {
      type: String,
      required: true
    },
    counts: {
      type: Object,
      default: () => ({ img: 0, audio: 0, rating: 0 })
    },
    purgeDeadline: {
      type: [String, null],
      default: null
    }
  },
  methods: {
    async onRestore() {
      const res = await this.$store.dispatch('restoreObject', this.uid);
      if (!res.error) {
        this.$store.dispatch('loadObjects');
      }
    },
    copyItem() {
      this.$router.push({ name: 'copy', params: { id: this.uid } });
    },
    async deleteItem() {
      /**/
      await this.$refs.modal.openModal(DeleteModal, {
        id: this.uid,
        title: this.title
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.obj-card {
  position: relative;
  display: flex;
  background-color: #fff;
  border: 1px solid #e4edfb;
  padding: 16px 22px 16px 16px;
  border-radius: 16px;
  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: var(--rir-arrival);
    margin-right: 12px;
    width: 72px;
    height: 72px;
    border-radius: 8px;
    > img {
      height: 100%;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__address {
    opacity: 0.72;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__counters {
    margin-top: 14px;
  }
  &__restore {
    background-color: transparent;
    cursor: pointer;
  }
  .pointer {
    cursor: pointer;
  }
}
.opacity-32 {
  opacity: 0.32;
}
</style>
