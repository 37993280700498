<template>
  <div>
    <div
      class="contact-item flex align-center"
      v-for="(item, index) in list"
      :key="index"
    >
      <r-icon
        class="mr-2"
        fill="rocky"
        :icon="icon"
        :size="iconSize"
      />
      <span class="contact-item__text feta flex-1">{{ item }}</span>
      <div
        class="contact-item__remove-btn"
        @click="remove(index)"
      >
        <r-icon
          class="ml-2"
          icon="delete"
          fill="fargo"
          :size="iconSize"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactItems',
  props: {
    icon: {
      type: String,
      default: 'telephone'
    },
    iconSize: {
      type: String,
      default: '16'
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      list: []
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.list = val;
      }
    }
  },
  methods: {
    remove(index) {
      const list = this.list.filter((el, i) => i !== index);
      this.list = list;
      this.$emit('input', list);
    }
  }
};
</script>

<style lang="scss" scoped>
.contact-item {
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  &__text {
    opacity: 0.72;
  }
  &__remove-btn {
    cursor: pointer;
  }
}
</style>
